import dispatchEvent from '../utils/dispatch-event';
import onDocumentReady from '../utils/on-document-ready';
import { isPromotionalItem, handlePromotions } from '../utils/promotions';

class QuantitySelector extends HTMLElement {
  constructor() {
    super();

    onDocumentReady(() => {
      this.initEventListeners();
    });
  }

  get decrementButton() {
    return this.querySelector('[data-decrement]');
  }

  get incrementButton() {
    return this.querySelector('[data-increment]');
  }

  get productId() {
    return this.dataset.productId;
  }

  get variantId() {
    return this.dataset.variantId;
  }

  get currentQuantity() {
    return Number(this.querySelector('[data-quantity]').innerText);
  }

  get lineItemPrice() {
    return Number(this.dataset.lineItemPrice);
  }

  get inventory() {
    return this.dataset.lineItemInventory;
  }

  changeQuantity(newQuantity) {
    fetch(`/cart/update.js?updates[${this.variantId}]=${newQuantity}`).then(
      () => {
        dispatchEvent('cart:quantity-updated', document);
      }
    );
  }

  increment() {
    if (this.productId != undefined) {
      if(isPromotionalItem(this.productId)){
        handlePromotions(this.productId, this.variantId, this.lineItemPrice);
        return;
      }
    }
    if (
      this.inventory === 'preorder' ||
      this.inventory > this.currentQuantity
    ) {
      this.changeQuantity(this.currentQuantity + 1);
    }
  }

  decrement() {
    this.changeQuantity(this.currentQuantity - 1);
  }

  initEventListeners() {
    this.decrementButton.addEventListener('click', () => this.decrement());
    this.incrementButton.addEventListener('click', () => this.increment());
  }
}

customElements.define('quantity-selector', QuantitySelector);
